/* You can add global styles to this file, and also import other style files */
@import 'theme.scss';

// GLOBAL VARS
:root {
  --headerBackground: url('https://firebasestorage.googleapis.com/v0/b/helperapp-af994.appspot.com/o/Background%2FHelperApp_ProfielBanner_130120-01.png?alt=media&token=cb1797ba-119c-465f-802c-be025d98b6c4');
  --primary: #40539f;
  --accent: #8cbf4d;
  // --accent:
  --logo: url('https://firebasestorage.googleapis.com/v0/b/hallo-bot.appspot.com/o/HalloVriend_Logo_500x500-02.svg?alt=media&token=e8b5ea3e-94e5-4899-bd6f-b94ace15a4b6');
  // --loginVideo : url('https://firebasestorage.googleapis.com/v0/b/helperapp-af994.appspot.com/o/Background%2FHelperApp_Banner.mp4?alt=media&token=01c88265-dd41-48dc-86df-dca8f2d63cf6');
}
